

body {
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.hPaneTopbarRight {
    overflow: hidden !important;
    max-height: 100%;
}

.e-appbar.e-inherit {
    border: 0;
    box-shadow: none;
}

.e-appbar-separator {
    margin-right: 12px;
    color: gray;
}