#loginContainer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 100%;
}
#containerChild1 {
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  align-items: center;
  height: 100%;
}
#containerSubChild1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  flex: 7;
}
#forgot-msg {
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: right;
  font-size: 14px !important;
  width: 100%;
  margin-top: -20px !important;
}
#register-msg2 {
  color: #0d6efd;
  cursor: pointer;
  padding: 7px;
}
#google-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 12px auto !important;
}
#containerSubChild2 {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
}
#leftBottomImage {
  width: 100%;
}
#containerChild2 {
  height: 100%;
  flex: 1;
}

/* #google-btn:hover {
  background-color: rgb(128, 211, 239);
} */
#google-btn {
  width: 258px;
  margin-top: 7px;
  background-color: #fff;
  border: 1px solid #dadce0;
  font-size: 14px;
  color: gray;
  height: 32px;
  font-weight: 500;
  border-radius: 4px;
}
