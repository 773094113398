.allocationlisttree {
  width: 300px;
  overflow: auto;
  border: 1px solid rgb(238, 238, 238);
  float: left;
  border-radius: 7px;
  margin-right: 18px;
}

.allocationlisttree .allocationlist-treeview {
  height: calc(100vh - 189px);
  overflow: auto;
}

.allocationlisttree .allocationlist-treeview.undertabs {
  height: calc(100vh - 244px);
  overflow: auto;
}

/* .allocationlisttree .allocationlist-treeview > ul {
  height: 100%;
} */
.allocationlisttree .allocationlist-treeview > ul:first-of-type {
  overflow: hidden !important;
}

.allocationlisttree .e-text-content .e-list-text {
  width: calc(100% - 20px);
}

.allocationlisttree .e-list-item.e-level-1.e-has-child > .e-fullrow {
  background-color: rgb(250, 250, 250);
}

.allocationlisttree ul li .columnheader {
  font-weight: bold;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(238, 238, 238);
}

.allocationlisttree .clearparent {
  cursor: pointer;
}

.allocationlisttree .treeviewheader {
  padding-left: 53px;
  padding-right: 27px;
}

.allocationlisttree .three-dots-parent {
  border: 1px solid rgb(238, 238, 238);
  height: 36px;
  align-items: center;
}

.allocationlisttree .three-dots {
  align-self: center;
}

.allocationlistdetail .details-header {
  font-size: 18px;
  font-weight: 800;
  color: #00000073;
  padding-bottom: 8px !important;
  letter-spacing: 1.5px;
}

.allocationlistdetail .details-body {
  height: calc(100% - 27px);
  width: 30%;
}

.allocationlist-treegrid .e-treecolumn-container .e-none {
  display: inline-block !important;
  width: 15px !important;
}

/* AllocationDetails.css */

.personnel-header {
  font-size: 20px;
  font-weight: 800;
  color: #00000073;
  padding-bottom: 20px !important;
  letter-spacing: 1.5px;
}

/* alllocationDetails.css */
.details-body {
  width: 40%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  padding: 30px !important;
  transition: transform 0.3s, box-shadow 0.3s;
}

.details-body:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.details-header {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  border-bottom: 2px solid #fc4c02;
  padding-bottom: 10px;
}

.details-body {
  margin-top: 15px;
  padding: 15px;
}

.details-content {
  margin-top: 10px;
}
.labelcolor-none {
  color: #aaa;
  cursor: default;
}

.labelcolor-active {
  color: #fc4c02;
  cursor: pointer;
  font-size: 14px;
  line-height: 32px;
}

.labelcolor-active:hover {
  text-decoration: underline;
}
#remove {
  color: #fc4c02;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-tree {
  margin-top: 10px;
}
