#divResourceLinkIcon .imagecol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#divResourceLinkIcon .image-container {
  height: 200px;
  width: 200px;
  border: 1px solid #dfe1e6;
  position: relative;
  overflow: hidden;
  margin: 1px;
}

#divResourceLinkIcon #custom-img {
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform-origin: center;
}

#divResourceLinkIcon #img-drag {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: move;
  background-color: rgba(0, 0, 0, 0);
}

#divResourceLinkIcon #img-canvas {
  display: none;
}

#divResourceLinkIcon .e-custom-file {
  display: none;
}

#divResourceLinkIcon .image-control {
  display: flex;
  flex-direction: column;
}

#divResourceLinkIcon .sliderwrap {
  width: 200px;
}
