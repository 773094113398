.treegrid {
  /* border-right: 1px solid lightgray; */
  height: 100%;
}

.treegrid .e-treecolumn-container .e-none {
  display: inline-block !important;
  width: 15px !important;
}

.treegrid .e-gridheader {
  display: none !important;
}

.treegrid .e-grid {
  border: none !important;
  padding: auto 10px !important;
}

.treegrid .e-grid .e-gridheader {
  border: none !important;
}

.treegrid .e-grid .e-rowcell {
  padding: 5px 10px !important;
  border-style: none !important;
}

.treegrid .e-grid {
  height: 100% !important;
}

#dlgAddEditRecorddialog .e-footer-content {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

#dlgAddEditRecorddialog .correspondence.e-footer-content {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}

#dlgAddEditRecorddialog .e-footer-content .switch-employee {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  margin-left: 20px;
}
