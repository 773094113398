.divlinklist .linkitem {
  font-size: 14px;
  padding: 5px 16px;
  color: rgba(0, 0, 0, 0.87);
}

.divlinklist .linkitem:hover {
  background-color: lightgray;
}
.divlinklist .linkitemtext {
  position: absolute;
  top: 5px;
  left: 40px;
  cursor: pointer;
}

.divlinklist .linkitemoptions {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  display: none;
}

.divlinklist .linkitem:hover .linkitemoptions {
  display: block;
}
