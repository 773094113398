.userlist-treegrid .e-treecolumn-container .e-none {
  display: inline-block !important;
  width: 15px !important;
}

.userlist-grid tr.e-filterbar {
  display: none;
}

.userlist-grid .fontawesome {
  font-family: 'FontAwesome' !important;
}

.divuserlist .three-dots-parent {
  position: absolute;
  right: 16px;
  top: 54px;
  z-index: 1;
  width: auto !important;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text:focus {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}

.divuserlist .smallfont {
  font-size: 11px;
  color: darkgray;
}

.divuserlist .userrole {
  text-align: right;
}

.divuserlist .topsection {
  margin-left: -12px !important;
}

.divuserlist .isexternal {
  background-color: #f7ece4;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
}
