.period-selector {
  /* width: 580px !important; */
  width: 460px !important;
  min-width: 300px !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fc4c02;
}

.periodSelector-input {
  border: none !important;
  font-size: 14px;
  width: 80%;
  font-weight: 300;
  min-width: 70%;
  padding: 10px;
  background-color: transparent;
}

.periodSelector-icons {
  cursor: pointer;
}

.periodSelector-dropdownIcons {
  margin-left: -3%;
}

.period-selector-disabled {
  pointer-events: none;
  color: gainsboro;
}

input.larger {
  margin: 0px 0px 0px 25px;
  transform: scale(1.3);
}

.close-toolbar-btn .e-tbar-btn-text {
  font-weight: 500 !important;
  color: brown !important;
}

.close-toolbar-ico .e-icons {
  color: brown !important;
}

/* #payslip-periodcontextmenu {
  width: 18vw;
} */
/* .e-menu-parent .e-ul {
  width: 170px;
} */
