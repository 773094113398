.non-selectedEmp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.non-selectedEmp img {
  display: inline-block;
  background-color: transparent;
  position: absolute;
  bottom: 0;
}
#profilebiodataviewer-tabcomponent {
  transition: height 0.3s ease !important;
}
.div {
  width: 97%;
  margin: auto;
}
.main-infi-div {
  width: 80%;
}
#Profile-Card {
  border: 1px solid #e3e3e3;
  border-bottom: none !important;
  border-radius: 10px 10px 0px 0px;
  padding: 30px;
  padding-bottom: 0px !important;
  margin: 10px 0px;
  margin-bottom: 0px !important;
  width: 100%;
  background-color: #ffffff;
  transition: all 0.3s ease;
  display: flex;
  min-height: 180px;
  position: relative;
  flex-direction: column;
}

#Profile-Card:not(.expanded) {
  height: auto;
  overflow: hidden;
  min-height: 115px;
}

#Profile-Card.expanded {
  height: auto;
}
#Profile-Card.expanded .pcard-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
#Profile-Card:not(.expanded) .pcard-details {
  display: none !important;
}
#Profile-Card .pcard-header {
  width: 90%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  margin-top: -32px;
  margin-bottom: 15px !important;
}

#Profile-Card .user-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  flex-shrink: 0;
  transition: all 0.3s ease;
}
#Profile-Card .user-image.large {
  width: 140px;
  height: 140px;
}
#Profile-Card .user-image.small {
  width: 80px;
  height: 80px;
}
#Profile-Card .imgEdit-pen {
  position: absolute;
  border: 2px solid #938b8b;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  color: #938b8b;
  cursor: pointer;
  background: #fefefe;
}
#Profile-Card .imgEdit-pen:hover {
  color: #fc4c02;
  border: 2px solid #fc4c02;
}
#Profile-Card .imgEdit-pen.large {
  padding: 6px;
  font-size: 16px;
}
#Profile-Card .imgEdit-pen.small {
  padding: 4px;
  font-size: 13px;
}
#Profile-Card .user-infocard {
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.user-infocard {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.user-namecard {
  font-size: 22px;
  font-weight: bold;
  color: #4a4949;
  text-transform: capitalize;
  margin-bottom: 4px;
  letter-spacing: 2px;
}
.occupation {
  border: 1px solid #fc4c02;
  padding: 4px 10px;
  border-radius: 20px;
  color: #fc4c02;
  margin-top: -2px;
  margin-left: 10px;
}
.emocode-class {
  color: #6a6464;
  margin-top: 4px;
  margin-bottom: 2px;
}
.pcard-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.pcard-details div {
  padding: 8px;
  border-radius: 5px;
  width: 182px;
  text-align: center;
  background: #f8f8f8;
  border: 1px solid #ddd;
  font-weight: 300;
}
.pcard-details div strong {
  font-size: 14px;
  color: #4f4e4e;
  line-height: 1.5;
}
.pcard-details div p {
  font-size: 14px;
  margin-bottom: 0;
}
.view-more-btn {
  align-self: center;
  width: 100px !important;
  padding: 8px 16px;
  background-color: #fefefe;
  color: #fc4c02;
  border: 1px solid #fc4c02;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  right: 30px;
  top: 50%;
}
.view-more-btn:hover {
  background-color: #e04807;
  color: #fefefe;
}
#DetailsDialog {
  width: 390px !important;
  max-width: 600px !important;
  min-height: 241px !important;
  left: unset !important;
  right: 12px !important;
  padding: 0px !important;
  top: 54px !important;
}
#DetailsDialog:after {
  content: '';
  position: absolute;
  right: 5%;
  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}
#DetailsDialog .e-dlg-content {
  width: 100% !important;
  padding: 0px !important;
}
#DetailsDialog .content-details-dialog {
  width: 100% !important;
  padding: 10px !important;
  border: none;
}
#DetailsDialog .e-dlg-content div {
  padding: 8px;
  border-radius: 5px;
  width: 100% !important;
  text-align: center;
  background: #f8f8f8;
  font-weight: 300;
  }
#DetailsDialog .detail-item {
  margin-bottom: 10px !important;
  }

#DetailsDialog .detail-label {
  font-weight: bold !important;
  display: block !important;
  margin-bottom: 4px !important;
  color: #333 !important;
  }

#DetailsDialog .detail-value {
  color: #555 !important;
  }
@media (max-width: 768px) {
  .user-infocard {
    flex-direction: column;
    align-items: center;
  }

  .pcard-details div {
    width: 100%;
  }
}
#Profile-Card .pcard-actions {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

#Profile-Card .pcard-actions button {
  background: none;
  border: none;
  font-size: 1.2em;
  color: #fc4c02;
  cursor: pointer;
  transition: color 0.3s ease;
}
#AllPerson {
  width: 100%;
  margin: auto;
  margin-top: -15px !important;
}
#AllPerson .e-tab .e-tab-header {
  width: 100% !important;
  box-sizing: border-box;
  border-top: none !important;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0px !important;
  min-height: 60px;
  background: #fefefe;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  width: 100% !important;
  position: relative;
  padding: 0px !important;
  margin-top: 0px !important;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-items {
  margin: 10px !important;
}
#AllPerson .e-tab .e-tab-header:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2) !important;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  width: 95px !important;
}
#AllPerson .e-tab .e-content {
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  transition: all 0.3s ease;
  display: flex;
  position: relative;
  flex-direction: column;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  font-size: 14px !important;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-item.e-active {
  background-color: transparent !important;
  border-top: 1px solid transparent !important;
  border-left: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  border-radius: 5px 5px 0px 0px !important;
  position: relative;
}

#AllPerson .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: #fc4c02 !important;
  padding: 15px 10px;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fc4c02;
}

#AllPerson .e-tab .e-tab-header .e-toolbar-item:not(.e-active) {
  border-bottom: none !important;
  border-radius: 0px !important;
}
#AllPerson .tabicon {
  margin-right: 10px;
  display: inline-block;
}
.content-basic-card {
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  height: 75vh;
}
#AllPerson .e-tab .e-content {
  transition: height 0.3s ease, border 0.3s ease;
}
#AllPerson .e-card-resize-container .row .col-12 {
  padding: 0px !important;
}
#tabC .e-tab .e-tab-header {
  background-color: transparent;
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
}
#tabC .e-tab .e-tab-header:hover {
  box-shadow: none !important;
}
#tabC .e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: transparent !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active {
  background-color: #fc4c02 !important;
  border-top: 2px solid #fc4c02 !important;
  border-left: 2px solid #fc4c02 !important;
  border-right: 2px solid #fc4c02 !important;
  border-radius: 5px 5px 0px 0px !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: #fff !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item:not(.e-active) {
  border-bottom: 2px solid #fc4c02 !important;
  border-radius: 5px 5px 0px 0px !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover,
#tabC
  .e-tab
  .e-tab-header
  .e-toolbar-item.e-active
  .e-tab-wrap:hover
  .e-tab-text {
  background-color: transparent !important;
  color: #fff !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text::after {
  content: '';
  position: absolute;
  bottom: -0px;
  left: 0%;
  transform: translateX(-0%);
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0px solid transparent;
}
.basic-profile-card .basic_card {
  margin-top: -3px !important;
  height: calc(100vh - 405px) !important;
}

#AllPerson .e-content.e-lib.e-touch {
  height: auto !important;
}
.ImageUploadDialog {
  width: 100%;
  height: auto;
  padding: 20px;
}

/* -----    ImageEditor Syncfusion Dialog      ---- */
#profile-dialog {
  width: 758px !important;
  height: 60vh !important;
  padding: 20px 0px !important;
}
#profile-dialog .e-footer-content {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}
#profile-dialog .e-dlg-content {
  padding-top: 18px !important;
}
.e-dialog .e-footer-content {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}
.begin-buttons {
  display: flex;
  gap: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 710px;
  margin: auto;
}
.button-container {
  display: flex;
  gap: 10px;
}

.button-container.show {
  display: flex;
  animation: slideInRight 0.5s ease-out forwards;
}

.button-container.hide {
  display: none;
  animation: slideOutLeft 0.5s ease-out forwards;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
.e-canvas-wrapper {
  width: 100% !important;
  height: calc(100vh - 588px) !important;
}
.e-image-editor {
  border: 1px solid #bdbdbd;
  position: relative;
  display: block;
  height: calc(100vh - 588px) !important;
}
.e-image-editor .e-toolbar {
  display: none !important;
}
.imgEditorbtns {
  background-color: #ddd;
  border-radius: 30px;
  text-transform: capitalize;
  box-shadow: none;
  padding: 20px !important;
  width: 130px;
  line-height: 24px;
}
.custom-img-open,
.custom-img-reset,
.custom-img-rotate,
.custom-img-zoomin,
.custom-img-zoomout {
  width: 40px !important;
  line-height: 18px !important;
  background-color: #efe6e6 !important;
}
.dialog-footer .e-btn .e-btn-icon {
  font-size: 17px !important;
  color: #656562 !important;
}
.last-buttons {
  display: flex;
  gap: 10px;
  margin-left: auto;
}
.e-custom-file {
  display: none;
}

div#ej2-image-editor_4_canvasWrapper {
  height: 55vh !important;
}
.e-img-editor-profile,
.e-img-editor-sample .control-wrapper {
  position: relative;
}

.e-img-editor-profile .e-profile.large {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
.e-img-editor-profile .e-profile.small {
  width: 80px;
  height: 80px;
}
.e-custom-wrapper {
  position: relative;
}
.e-custom-edit.large {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fc4c02;
  top: calc(100% - 60px);
  left: calc(100% - 45px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.e-custom-edit.small {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fc4c02;
  top: calc(100% - 36px);
  left: calc(100% - 30px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
#custom-edit .e-custom-icon.large::before {
  content: '\f303';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 18px;
  color: white;
  left: 10px;
  top: 10px;
  line-height: initial;
  position: absolute;
}
#custom-edit .e-custom-icon.small::before {
  content: '\f303';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 14px;
  color: white;
  left: 8px;
  top: 8px;
  line-height: initial;
  position: absolute;
}
.tailwind .e-custom-icon::before,
.tailwind-dark .e-custom-icon::before {
  top: 6px;
}

#custom-edit:hover {
  cursor: pointer;
  background-color: #e85a1c;
  transform: translateY(-5px);
}

.img-canvas.large {
  border-radius: 50%;
  border: 1px solid #ddd;
  max-width: 160px;
  max-height: 160px;
}
.img-canvas.small {
  border-radius: 50%;
  max-width: 80px;
  max-height: 80px;
}
.e-img-custom-open {
  float: left;
  margin-left: 0 !important;
}
.e-img-custom-reset {
  float: left;
}
.e-profile .e-custom-img,
.e-profile .e-custom-file {
  display: none;
}
.e-bigger #profile-dialog .e-dlg-header {
  font-size: 18px !important;
}
#profile-dialog .e-dlg-content > div {
  height: 100%;
  width: 100%;
}
