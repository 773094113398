.e-btn .e-btn-icon.e-icon-left,
.e-css.e-btn .e-btn-icon.e-icon-left {
  margin-left: -1.6667em;
}

.custom-dialog {
  min-height: 50px;
  padding: 0px;
}

.btncssClass {
  width: 18%;
}

.custom-dialog .e-dlg-content {
  max-height: none;
  overflow: visible;
}
.custom-dialog .e-footer-content {
  padding-right: 5%;
  padding-left: 5px;
  height: auto;
}

.e-btn.e-flat.e-primary {
  display: inline-block;
}


.dialog-contain {
  font-size: 15px;
  line-height: 26px;
}

.e-dialog .e-footer-content {
  display: flex;
}


.e-confirm-dialog
  .e-control
  .e-dialog.e-lib
  .custom-dialog
  .e-dlg-modal
  .e-popup
  .e-popup-open {
  max-height: none;
}

.custom-dialog .prompt-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.custom-dialog .prompt-input {
  width: 96%;
  padding: 8px;
  display: block;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #fc4c02;
  border-radius: 2px;
  box-sizing: border-box;
}

.custom-alert-dialog {
  padding-top: 10%;
  padding: 10px;
}

.e-dialog .e-footer-content {
  display: flex !important;
  justify-content: end !important;
  box-sizing: border-box !important;
  padding: 15px 15px 20px 0px !important;
}
.e-dialog .e-footer-content .e-btn {
  display: inline-block !important;
  padding: 0px !important;
  padding-right: 20px !important;
}
.bottom-rejecion {
  font-size: 14px !important;
  margin-top: 8px !important;
}
.e-dialog .e-footer-content .e-btn {
  padding: 10px !important;
  padding-right: 15px !important;
}

@media (min-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
        margin: 30px;
        width: 430px !important;
    }
}