#Timesheet .e-columnheader {
    display: none;
}

#GrdTimesheet_toolbarItems {
    border-top: 1px solid #e0e0e0;
    background-color: #e0e0e0;
}

#GrdTimesheet_toolbarItems .e-toolbar-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

#GrdTimesheet_toolbarItems .e-toolbar-items .toolbar-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.e-grid .e-headercell.customHeaderCss {
    font-weight: bolder !important;
}

.e-grid .e-headercell.customHeaderCss .e-headertext {
    font-weight: bolder !important;
}

.PeriodSelector {
    margin-left: 15px;
    float: left !important;
}

.DataDisplaySelector {
    margin-left: 15px;
    float: left !important;
}

/* .table-container {    
    height: calc(100vh - 255px);
}

.table-container {
    padding: 0.1em;
} */

/* .text-center {
    border-bottom: 1px solid black;
}

.content-c-align {
    text-align: center !important;
}

.itable {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    user-select: none;
}

.itable {
    border-spacing: 0em;
}

.itable .thead {
    flex: 0 0 auto;
    width: calc(100% - 1.2em);
}

@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
    .itable .thead {
        flex: 0 0 auto;
        width: calc(100% - 1.4em);
    }
}

.blank-space {
    height: 3px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.itable .tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
}

.itable .tbody .tr {
    width: 100%;
}

@-moz-document url-prefix() {
    .itable .tbody .tr {
        width: calc(100% - 1.2em);
    }
}

.itable .thead,
.itable .tbody .tr {
    display: table;
    table-layout: fixed;
}

.itable th {
    padding: 0.1em;
    padding: 6px !important;
    border: 1px solid black;
    text-align: center !important;
    font-weight: normal !important;
}

.itable tr {
    padding: 0.1em;
}

.itable td {
    padding: 6px !important;
    padding: 0.1em;
    border: 1px solid rgb(85, 85, 85);
}

.itable .td1 {
    width: 18.7%;
}

.itable .td2 {
    width: 7.5%;
    border-right: 1px solid black;
}

.itable .td3 {
    width: 7.5%;
    border-bottom: 1px solid black;
}

.itable .td4 {
    width: 36%;
    border-bottom: 1px solid black;
}

.itable .td5 {
    width: 34%;
    border-bottom: 1px solid darkgrey;
}

.itable .td6 {
    width: 6%;
    border-right: 1px solid darkgray;
}

.itable .td7 {
    width: 6%;
    border-right: 1px solid black;
}

.itable .td8 {
    width: 6%;
    border-right: 1px solid black;
}

.itable .td9 {
    width: 6%;
    border-right: 1px solid black;
}

.itable .td10 {
    width: 17%;
    text-align: right;
    border-right: 1px solid darkgray;
}

.itable .td11 {
    width: 17%;
    text-align: right;
    border-right: 1px solid black;
}

.itable .bodytd1 {
    width: 4%;
    text-align: center !important;
    border-right: 1px solid darkgray;
}

.itable .bodytd2 {
    width: 4.5%;
    text-align: left;
    border-right: 1px solid black;
}

.itable .bodytd3 {
    width: 24%;
    text-align: center !important;
    border-right: 1px solid black;
}

.itable .bodytd4 {
    width: 5%;
    text-align: center !important;
    cursor: pointer;
    user-select: none;
    border-right: 1px solid black;
}

.itable .bodytd5 {
    width: 6%;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border-right: 1px solid darkgray;
}

.itable .bodytd6 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.itable .bodytd7 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.itable .bodytd8 {
    width: 6%;
    text-align: center;
    border-right: 1px solid black;
}

.itable .bodytd9 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.itable .bodytd10 {
    width: 6%;
    text-align: center;
    border-right: 1px solid black;
}

.summary {
    height: 45px !important;
    width: 98.7% !important;

    @-moz-document url-prefix() {
            {
            width: calc(100% - 1.2em) !important;
        }
    }

    border: 1px solid black !important;
    margin-top: 2px !important;
}

@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
    .summary {
        height: 45px !important;
        width: calc(100% - 1.45em) !important;

        @-moz-document url-prefix() {
                {
                width: calc(100% - 1.2em) !important;
            }
        }

        border: 1px solid black !important;
        margin-top: 2px !important;
    }
}

.summary-table {
    height: 100%;
    width: 100%;
}

.summary-td1 {
    width: 22.5%;
    text-align: right;
    font-weight: 600;
    padding-right: 5px;
    background-color: #faf0e6b5;
    border-right: 1px solid black;
}

.summary-td2 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td3 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td4 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td5 {
    width: 6%;
    text-align: center;
}

.summary-td6 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td7 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
} */

#TimeAdd:hover,
#TimeEdit:hover,
#TimeDelete:hover {
    color: #fc4c02;
}

#attendance .e-grid .e-gridcontent .e-content {
    overflow-y: auto !important;
    scrollbar-width: none !important;
    margin-top: 0px !important;
}

#attendance .e-grid .e-gridheader {
    padding-right: 0px !important;
    color: black !important;
}

#attendance .e-grid .e-footerpadding {
    padding-right: 0px !important;
}

#attendance .e-grid .e-summaryrow .e-summarycell {
    background-color: #fa8858;
    color: white !important;
    font-weight: 500 !important;
    text-align: right !important;
}

#attendance .e-grid .e-summarycell {
    padding-right: 7px !important;
}