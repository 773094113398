.favorite-card {
  margin-top: 20px;
  width: 100%;
  padding: 10px !important;
}
#favorite-card-id {
  height: auto; /* Allow content to display fully by default */
  overflow: hidden;
  transition: height 0.3s ease; /* Smooth height transition */
}

#favorite-card-id:not(.expanded) {
  height: 0; /* Collapse when not expanded */
}

.favorite-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 16px !important;
  border-bottom: 1px solid lightgray;
  margin: 0px 12px;
}
.favorite-card-content {
  padding: 0px !important;
  margin: 0px !important;
}
.favorite-content-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  font-size: 14px;
  /* margin: 8px 0px; */
  color: #545353;
}
.favorite-content-row:hover {
  color: #fc4c02 !important;
}
