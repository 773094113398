.employee-payslip {
  height: calc(100vh - 505px) !important;
  border-right: 1px solid rgb(191, 191, 191) !important;
  /* overflow: auto !important; */
  min-width: 430px;
}
.employee-payslip tr[aria-expanded] td,
#netpaygrid td {
  line-height: 26px;
}

.payslip-container {
  padding-right: 25px !important;
}
.employee-payslip .e-treecolumn-container .e-none {
  display: inline-block !important;
}
.employee-payslip .e-grid {
  border: none !important;
}
.employee-payslip .e-grid .e-gridheader {
  border: none !important;
}

.employee-payslip .e-grid .e-rowcell {
  padding: 5px 10px !important;
  font-size: 14px !important;
  border-style: none !important;
}

/* .e-grid:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child .e-rowcell {
  padding: 8px !important;
} */

.employee-payslip
  .e-grid:not(.e-grid-min-height)
  .e-gridcontent
  tr
  td:not(.e-indentcell):first-child:empty,
.e-grid:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
  height: 29px !important;
}

.employee-payslip .e-treecolumn-container .e-none {
  width: 20px !important;
}

.employee-payslip .e-headertext {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

/* employer grid */

.employer-payslip {
  min-width: 285px;
}

.employer-payslip .e-treecolumn-container .e-none {
  display: inline-block !important;
}

.employer-payslip .e-grid {
  border: none !important;
}

.employer-payslip .e-grid .e-gridheader {
  border: none !important;
  border-bottom: 1px solid rgb(191, 191, 191) !important;
  margin-top: 18px !important;
}

.employer-payslip .e-grid .e-rowcell {
  border-style: none !important;
}

.employer-payslip .e-grid .e-rowcell.border-top {
  border-top: 1px solid rgb(191, 191, 191) !important;
}

.employer-payslip .e-headertext {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

/* payee style started */

.paye-style .e-grid {
  border: none !important;
  padding: 10px !important;
  margin: 10px !important;
  padding-right: 50px !important;
}

.paye-style .e-grid .e-rowcell {
  border-style: none !important;
}

.paye-style .e-grid .e-gridheader {
  border: none !important;
}

#payslip-style {
  height: calc(100vh - 554px);
  overflow: auto;
}

.payeHeader-style {
  font-size: 14px;
  font-weight: 470;
}

/* payslab-style */
.payslab-style .e-grid {
  border: 1.4px solid black !important;
}

.payslab-style .e-rowcell {
  border-top: 1.4px solid black !important;
}

.payslab-style .e-headercell {
  background-color: #bfbfbf !important;
  color: white;
}

.payslab-style .e-headertext {
  font-size: 14px !important;
}

.payeeName {
  font-size: 14px !important;
  font-weight: bold !important;
}

.touch-cursor {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAJwSURBVEhLnZRJqA5QFMevmSgJz2yDzIrMRFE2hkKs9EiZ1ygbNhILFqY8KRRFFBkyKyyJEkpZCivFzgK/373vvL4xw79+vXO/933nnnvO/95OqbGGwgZYAF3hF/hdeQnn4DX8tXrAfrgDy9vXleoMs8HEF6AF/qh+cA/W5VXRGLDyXbAVZoDJlRs8g0l51URWZtK5eZXSZLgFVtYKS2ANHIEn4GmULTP5iLxqII8fla6AuzAyr+rVB9pgb16lNBFuQ9283NWeqilg0ujtVDgON+E8LINIYPWeRlmY/6vSHoijefyodDtcBXuoM4bBITgD9rkbPIXeMBiuQ5WiQgdlT9U0MGkjO+4GB6m2QFTtjCygQw/a/zr9+NIJaDbt7vC4hGk02G91DMaVsBxJ8ys9+amEuR3vSphlu+y17ZkDtkF9hvDyV9CyWSaO436HviXMXxpUwqzhYMu+wEL4CI/Avo4FW2K1XaBDD8ENZsJhP0AO82AJs3bA6hJWybZot7VwAL6B+XLCV+CNeg7TQZ9qr4HgjfPHb8AEtfoBH6A/zAdbthSy9G64wctxqoR5023goLxdl6CRTsIVsIA6XYRZJUz7wJbEgEL2tJG06lmw2jp7OlmrGpJXKa0H34TNMApsj72r3SxkQhN78p5+UCkfHpNPyKtyo9zgNFwD7Wef/aE+r31S1SrworTUlq5/7fELOAraK6SltOEAWATacyPEPQjNg7ZGV9bPfFA2gVW9BxPqUx3g9BfDTvgJ2izUCy5D3MamsqfjwXfahPpUTysL8MVbmVcl6Q2IB+2f5AArh2N8H9z4v5M2k1f/LVQkTek3/Z5voc6r0wAAAAAASUVORK5CYII=),
    auto;
}

.payslip-container {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* pointer-events: none; */
}