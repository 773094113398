/* #divCompanyList #divcompanylist-grid {
  width: 25%;
} */

#divCompanyList .companylist-treegrid .e-treecolumn-container .e-none {
  display: inline-block !important;
  width: 15px !important;
}

#divCompanyList .companylist-treegrid .e-grid .e-gridheader {
  padding-right: 0px !important;
}
.company-header {
  padding: 12px 14px;
  letter-spacing: 1.5px;
  font-weight: 600;
  font-size: 20px;
  /* background: linear-gradient(
    to right,
    #615e5d0a 0%,
    #a5a3a03c 51%,
    #48464516 100%
  ); */
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  margin: 10px auto;
  margin-bottom: 24px;
}
