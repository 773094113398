#banktree .bank-treeview {
  height: calc(100vh - 260px);
  overflow: auto;
}
/* #banktree .bank-treeview > ul {
  height: 100%;
} */
#banktree .bank-treeview > ul:first-of-type {
  overflow: hidden !important;
}

#banktree .e-text-content .e-list-text {
  width: calc(100% - 20px);
}

#banktree .e-list-item.e-level-1 > .e-fullrow {
  background-color: rgb(250, 250, 250);
}

#banktree ul li .columnheader {
  font-weight: bold;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(238, 238, 238);
}

#banktree .clearparent {
  cursor: pointer;
}

#banktree .treeviewheader {
  padding-left: 53px;
  padding-right: 27px;
}

.treegridbanklist .e-treecolumn-container .e-none {
  display: inline-block !important;
  width: 15px !important;
}
