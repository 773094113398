#pivotpayrollvariancereport .e-pivotfieldlist {
  position: absolute !important;
  width: auto !important;
  top: 6px !important;
  right: 10px !important;
}

#pivotpayrollvariancereport .e-pivotfieldlist .e-icons {
  background-color: transparent;
}
