.genFormat-header {
  width: 100%;
  letter-spacing: 2px;
  background: #f4875833;
  padding: 16px 20px;
  color: #000;
  font-size: 20px;
  font-weight: normal;
  border-radius: 8px 8px 0 0;
  margin: 12px 0px;
}
