#divResourceLink .row {
  padding-top: 8px;
  padding-bottom: 8px;
}

#divResourceLink .icn {
  height: 28px;
  width: 28px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
}
