#custrepopreview .custrepopreviewgrid {
  overflow: auto;
}

#custrepopreview .custrepopreviewgrid .e-headertext {
  padding-right: 15px;
}

#custrepopreview .custrepopreviewgrid th.e-rightalign .e-filtermenudiv {
  position: absolute;
  right: 10px;
}

#custrepopreview .periodsel_lblasondate {
  font-size: 14px;
}

#defaultDialog-custrepopreview .e-dlg-content > div {
  height: 100%;
}

#pivotcustrepopreview #pivotcustrepopreview_grid .e-gridcontent {
  height: calc(100vh - 322px) !important;
  overflow: auto;
  scrollbar-width: none;
}

#pivotcustrepopreview #pivotcustrepopreview_grid .e-gridcontent .e-content {
  height: auto !important;
}

#pivotcustrepopreview .e-pivotfieldlist {
  position: absolute !important;
  width: auto !important;
  top: 6px !important;
  right: 10px !important;
}

#pivotcustrepopreview .e-pivotfieldlist .e-icons {
  background-color: transparent;
  top: 5px;
}
@media (max-width: 768px) {
  #custrepopreview .custrepopreviewgrid {
    height: calc(100vh - 540px) !important;
  }
  #pivotcustrepopreview #pivotcustrepopreview_grid .e-gridcontent {
    height: calc(100vh - 625px) !important;
  }
}
