.divupcomingeventlist {
  width: calc(100% - 0px);
}

.divupcomingeventlist .e-gridheader {
  display: none;
}

.stickydivprev {
  /* background-color: #fdfdfd !important; */
  border-radius: 6px !important;
  position: relative !important;
}

#upcomingevents_body {
  border-radius: 0px !important;
  height: calc(100% - 100px) !important;
}

.event-card.highlight-today {
  border: 2px solid #fc4c02 !important;
  background-color: #ffebeba8 !important;
}

/* Event card styling */
.event-card {
  width: 97%;
  min-height: 75px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 0px 15px;
  background-color: #ffffff;
  border: 1px solid #e9e4e4;
  border-radius: 10px;
  position: relative;
  transition: transform 0.2s ease;
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-card::before {
  content: '';
  position: absolute;
  left: 12px;
  width: 10px;
  height: 10px;
  background-color: #fc4c02;
  border-radius: 50%;
}

.event-card::after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 44px;
  width: 2px;
  height: 60px;
  background-color: #ddd;
}

/* .event-card .event-icon {
  font-size: 24px;
  color: #fc4c02;
  position: absolute;
  top: 15px;
  left: -30px;
} */

/* Date circle styling */
.event-date-circle {
  width: 46px;
  height: 46px;
  /* background-color: rgb(181 178 178); */
  background: #fc4c02;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  font-weight: 500;
  position: absolute;
  right: -18px;
  top: 13px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.event-date-circle .month {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 2;
}

.event-date-circle .day {
  font-size: 15px;
  line-height: 1;
}

/* Event information styling */
.event-info {
  margin-left: 45px;
  /* Adjusted for better spacing from the date circle */
}

.event-info .event-name {
  font-size: 15px;
  color: #757171;
  font-weight: 400;
  letter-spacing: 1px;

  margin: 0;
}

/* .event-info .event-details {
  font-size: 13px;
  color: #666;
  margin: 5px 0;
} */

.divupcomingeventlist .upcomingeventlist-grid.overflowhidden .e-content {
  overflow-y: hidden !important;
}

.divupcomingeventlist .upcomingeventlist-grid .e-content {
  scrollbar-width: thin;
  padding: 10px 16px 8px 20px !important;
}

.divupcomingeventlist .upcomingeventlist-grid .e-rowcell {
  padding: 0px 10px !important;
}

.divupcomingeventlist .upcomingeventlist-grid .e-gridcontent tr:first-child td {
  padding-top: 10px !important;
}

.divupcomingeventlist .upcomingeventlist-grid {
  border-color: #fff !important;
}

.divupcomingeventlist .upcomingeventlist-grid td.e-active,
.divupcomingeventlist .upcomingeventlist-grid.e-gridhover tr[role='row']:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background: #fff !important;
}

.divupcomingeventlist .upcomingeventlist-grid .e-content:hover {
  scrollbar-width: thin;
}

.divupcomingeventlist .upcomingeventlist-grid .e-clipboard {
  display: none !important;
}

.divupcomingeventlist .upcomingeventlist-viewall {
  text-align: center;
  cursor: pointer;
  padding: 14px 10px;
  color: #0000008a;
  width: calc(100% - 0px);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 26px;
  /* background-color: #fefefe; */
  /* border-top: 1px solid #f4f3f1; */
  /* position: absolute; */
  /* bottom: -38px; */
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .divupcomingeventlist .upcomingeventlist-grid.overflowhidden .e-content {
    height: 275px !important;
  }

  .upcomingeventlist-grid .e-gridcontent {
    height: 275px !important;
    padding-left: 10px;
  }

  .divupcomingeventlist .calendar-photo {
    font-size: 34px !important;
  }

  .divupcomingeventlist .holidayinfo {
    padding: 25px 0px;
  }

  .divupcomingeventlist .holiday-month {
    line-height: 10px;
    top: 14px !important;
  }

  .divupcomingeventlist .calendar-number {
    top: 20px;
  }

  .divupcomingeventlist .holiday-name {
    top: 15px;
  }
}