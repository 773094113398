#divcustomreportlist {
  padding-left: 16px;
}

#divcustomreportlist .createnew {
  display: flex;
  position: absolute;
  left: calc(20px);
  width: 160px;
  /* font-size: 14px; 
  line-height: 26px; */
  border: 1px solid darkgray;
  background-color: lightgrey;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

#divcustomreportlist .footer {
  display: flex;
  position: fixed;
  bottom: 30px;
  left: calc(50% - 50px);
}

#divcustomreportlist .footer > div {
  display: flex;
  /* font-size: 14px; 
  line-height: 26px;*/
  /* border: 1px solid darkgray;
  background-color: lightgrey; */
  padding: 5px;
  text-align: center;
  cursor: pointer;
  /* width: 150px;
  margin-left: 10px; */
  color: lightgray;
}

#divcustomreportlist .footer > div:hover {
  color: black;
}
@media (max-width: 768px) {
  .defaultDialog-custlistrepopreview-class {
    height: 60vh !important;
  }

  .period-selector {
    width: 0px !important;
  }
  .divcustomreportlist-divClass {
    width: 45%;
    height: auto;
    margin: auto;
  }
}
