#divreports .e-tab-header .e-indicator {
  background-color: transparent !important;
}

#divreports .e-toolbar-items {
  width: 27%;
}

#divreports .e-toolbar-item.e-template {
  width: 100%;
  text-align: center;
}

#divreports .e-tab .e-content {
  margin-top: 20px;
}
