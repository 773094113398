.contract-json {
  /* border-right: 1px solid lightgray; */
  height: 100%;
}
.contract-json .e-treecolumn-container .e-none {
  display: inline-block !important;
}
.contract-json .e-gridheader {
  display: none !important;
}
.contract-json .e-grid {
  border: none !important;
  padding: auto 10px !important;
}
.contract-json .e-grid .e-gridheader {
  border: none !important;
}

.contract-json .e-grid .e-rowcell {
  padding: 5px 10px !important;
  border-style: none !important;
}
#contract-header {
  font-size: 16px !important;
  font-weight: 450 !important;
}

.contract-info1 {
  width: 40% !important;
  font-size: 14px !important;
}
.contract-info2 {
  width: 60% !important;
  font-size: 14px !important;
  text-align: right;
}
.contract-body {
  border-top: 2px solid #fc4d02f1 !important;
  height: calc(100% - 55px);
}

.contract-body .contract-content {
  height: calc(100% - 37px);
  line-height: 34px;
  margin-top: 3% !important;
  margin-left: 2% !important;
}
.contract-header {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: rgb(122, 119, 119);
}
.contract-custom-contextmenu ul.e-menu-parent.e-ul {
  max-height: 400px;
  overflow: auto;
}

.contract-job {
  padding-top: 5px;
  height: 100%;
}

.contract-job .job-doc {
  height: calc(100% - 50px);
}

.contract-json .e-treegrid .e-columnmenu {
  right: 0;
}

.contract-json .e-toolbar-item.e-tbtn-align {
  min-width: 20px;
}

.e-input-group.e-control-wrapper.border-0.text-end.e-readonly.e-ddl.e-valid-input::after,
.e-input-group.e-control-wrapper.border-0.text-end.e-readonly.e-ddl.e-valid-input::before {
  border: none !important;
  border-width: 0 0 0 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

/* Hide up and down arrows on input type number */
/* Chrome, Safari, Edge, Opera */
.contract-body input::-webkit-outer-spin-button,
.contract-body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.contract-body input[type='number'] {
  -moz-appearance: textfield;
}

/* new */
/* .contract .e-treecolumn-container .e-none {
  display: inline-block !important;
}
.contract .e-gridheader {
  display: none !important;
}
.contract .e-grid {
  border: none !important;
  padding: auto 10px !important;
}
.contract .e-grid .e-gridheader {
  border: none !important;
}

.contract .e-grid .e-rowcell {
  padding: 0px 0px !important;
  border-style: none !important;
}
#contract-header {
  font-size: 16px !important;
  font-weight: 450 !important;
}

.contract-info1 {
  width: 40% !important;
  font-size: 14px !important;
}
.contract-info2 {
  width: 60% !important;
  font-size: 14px !important;
  text-align: right;
}
.contract-body {
  border-top: 1px solid rgb(191, 191, 191) !important;
} */

.allocationtree {
  height: 300px;
  overflow: auto;
}

.allocationtree .e-treeview ul {
  height: 100%;
}

.allocationtree ul:first-of-type {
  overflow: hidden !important;
}
#defaultDialog-allocatonlist .e-dlg-content {
  overflow: hidden !important;
}

.custom-context-menu .e-contextmenu {
  max-height: 60%;
  overflow-y: auto;
}

.custom-context-menu .e-menu-parent.e-ul {
  max-height: 60%;
  overflow-y: auto;
}
