#divCompany {
  display: flex;
  flex-wrap: wrap;
}

#divCompany .row {
  padding-top: 8px;
  padding-bottom: 8px;
}

#divCompany .basicinfo-add {
  width: 50%;
}

#divCompany .basicinfo-edit {
  width: 100%;
}

#divCompany .settinginfo {
  width: 50%;
  padding-left: 20px;
}

#divCompany .panelheight {
  display: flex;
  width: 100%;
  max-height: calc(100vh - 162px);
  overflow-y: auto;
  overflow-x: hidden;
  /* scrollbar-width: none; */
}

#divCompany .e-footer-content-temp {
  padding: 8px;
  width: 100%;
  text-align: right;
}
