.e-schedule .e-timeline-view .e-resource-left-td {
  width: 15% !important;
}
.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos > div {
  padding: 10px;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .fa-regular.fa-download.e-icons {
  color: #000000 !important;
}

#schedule_Toolbar.e-toolbar .e-toolbar-items {
  width: 100% !important;
}

#schedule_Toolbar.e-toolbar .e-toolbar-items .e-toolbar-item {
  width: 100%;
}

#schedule_Toolbar.e-toolbar .e-toolbar-items .e-toolbar-item>div {
  width: 100%;
}