#payrollemployeelist-grid {
  width: 100%;
  overflow: auto;
}

#payrollemployeelist-grid .e-gridcontent {
  width: 100%;
}

#payrollemployeelist-grid.minwidth .e-gridcontent {
  width: 300px;
}

#tabpayrolllist > .e-content {
  padding-left: 18px;
  padding-right: 12px;
  height: 100% !important;
}

#PivotView #PivotView_grid {
  width: 100% !important;
}

#PivotView .e-gridcontent {
  /* height: calc(100vh - 567px); */
  overflow: auto;
}

#PivotView .e-content {
  height: calc(100vh - 404px) !important;
}

#PivotView .close-toolbar-ico .e-icons {
  color: black !important;
  background-color: transparent !important;
}

.e-pivotview .e-pivot-toolbar {
  padding: 0px !important;
  margin: 0px !important;
}

.payroll-header-label {
  width: 100%;
  letter-spacing: 2px;
  background: linear-gradient(
    to right,
    #615e5d0a 0%,
    #a5a3a03c 51%,
    #48464516 100%
  );
  padding: 16px 20px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px 8px 0 0;
  margin: 12px 0px;
}

#divpayroll .period-selector {
  width: 480px;
  min-width: 300px;
  background-color: #f4f1f154;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

#divpayroll .periodSelector-icons {
  cursor: pointer;
  color: #fc4c02;
  transition: color 0.3s;
}

#divpayroll .periodSelector-input {
  border: none;
  background: none;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  outline: none;
}

#divpayroll .periodSelector-dropdownIcons {
  color: #fc4c02;
  cursor: pointer;
}

@media (max-width: 768px) {
  #PivotView .e-gridcontent {
    height: calc(100vh - 395px);
  }

  .payrollemployeelist-grid-class {
    width: 100% !important;
    height: calc(-530px + 100vh) !important;
    scrollbar-width: none;
  }
}
