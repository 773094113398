#divChangepassword .row {
  padding-top: 8px;
  padding-bottom: 8px;
}

#divChangepassword .e-footer-content-temp {
  padding: 8px;
  width: 100%;
  text-align: right;
}
