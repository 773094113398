.email-container {
  background-color: #fff;
  /* max-width: 1420px; */
  margin-top: auto;
  height: calc(100vh - 165px);
  padding: 10px;
  border-radius: 10px;
  overflow: auto;
}
@media (max-width: 768px) {
  .email-container {
    max-width: 600px;
  }
}
.email-header {
  width: 100%;
  letter-spacing: 1px;
  /* background: linear-gradient(
    to right,
    #615e5d0a 0%,
    #a5a3a03c 51%,
    #48464516 100%
  ); */
  padding: 16px 20px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px 8px 0 0;
}

.email-content {
  width: 100%;
  padding: 20px 20px;
}

.email-row {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;
}

.email-label {
  width: 100%;
  font-weight: 500;
}

.email-input {
  width: 45%;
  padding: 8px;
  transition: border-color 0.3s;
}

.email-input:focus {
  border-color: #fc4c02;
  outline: none;
}

.email-sub {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.email-subrow {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sub-input-margin {
  margin-left: 0;
  width: 90%;
}
.email-subrow2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
}

.email-subrow2 .email-label {
  width: 100%;
}

.email-subrow2 .email-input {
  width: 100%;
}

.email-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
}
.textarea-width {
  width: 100%;
}
