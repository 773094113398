.home-layout-footer-right {
  width: 100%;
  padding-top: 2px;
  display: flex;
}

.home-layout-footer-right #layout-footer-right-div {
  /* width: 25%; */
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

.home-layout-footer-right #layout-footer-company {
  text-align: center;
  width: 87%;
  padding-left: 5px;
  font-size: 14px;
}

.logoutwarningtoast.e-toast-container
  .e-toast
  .e-toast-message
  .e-toast-actions {
  /* padding-top: 10px; */
  padding-bottom: 10px;
}

.logoutwarningtoast.e-toast-container
  .e-toast
  .e-toast-message
  .e-toast-content {
  padding-bottom: 0px !important;
}

.logoutwarningtoast.e-toast-container .e-toast .e-toast-progress {
  bottom: 1px !important;
}

.layoutHeaderLeft {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.layoutHeaderLeft .company-logo {
}

.layoutHeaderLeft .switchCompany-div {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.switch-div {
  width: fit-content;
  display: flex;
  /* justify-content: space-between; */
  margin-left: 50px;
  align-items: center;
  padding: 10px 16px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #535b86;
  background: #fefefe;
  transition: border 0.3s ease, color 0.3s ease, background 0.3s ease;
}

.switch-div:hover {
  background: #fae3d9;
  color: #fc4c02;
  border: 1px solid #f6d2c2;
}

.layoutHeaderLeft .switchCompany-logo {
  font-size: 22px;
  display: inline-block;
  margin-right: 10px;
}

.layoutHeaderRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  height: 40px;
}

.layoutHeaderRight .searchBox {
  display: block;
  width: 673px;
  margin-left: -30px;
  background-color: #f3f4f9;
  border-bottom: 1px solid #c7c7c7;
}

.layoutHeaderRight .searchBox .e-input-group.e-control-wrapper {
  width: 100%;
  background: none;
  border-bottom: none;
  padding: 5px 10px;
}

.layoutHeaderRight .searchBox .e-searchinput.e-input,
.layoutHeaderRight .searchBox .e-icons.e-search {
  color: #535b86;
  font-weight: 500;
  font-size: 15px;
}

/* after search icons css */
.heading-icons {
  width: 32%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 7px;
  margin-right: 10px;
  margin-left: auto;
}

.leave-icon-header {
  display: flex;
  width: 135px;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-radius: 30px;
  border: 1px solid #fff;
  color: #535b86;
  cursor: pointer;
  background: #fefefe;
  transition: border 0.3s ease, background 0.3s ease;
}

.leave-icon-header:hover {
  background: #e9e9f2;
  border: 1px solid #e9e9f2;
}

.leave-icon-header:hover i {
  transform: rotate(51.02deg);
  transition: transform 0.3s ease;
}

.leave-icon-header i {
  font-size: 22px;
  margin-right: 10px;
  color: #535b86;
  display: inline-block;
  transform: rotate(42deg);
}

.leave-icon-header span {
  font-size: 15px;
}

.header-sideicons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* .header-sideicons .fa-headphones,
.header-sideicons .fa-facebook-messenger,
.header-sideicons .fa-circle-user {
  font-size: 22px;
  display: inline-block;
  margin: 2px 5px;
  padding: 10px 14px;
  border-radius: 20%;
  cursor: pointer;
  background: #fefefe;
  transition: background 0.3s ease;
}
.header-sideicons .fa-headphones:hover,
.header-sideicons .fa-facebook-messenger:hover,
.header-sideicons .fa-circle-user:hover {
  background: #E9E9F2;
}
*/

.header-sideicons .fa-headset,
.header-sideicons .fa-bell,
.header-sideicons .fa-circle-user {
  width: 47px;
  height: 47px;
  font-size: 22px;
  display: inline-block;
  margin: 2px 5px;
  padding: 13px 13px;
  border-radius: 50%;
  cursor: pointer;
  background: #fefefe;
  transition: background 0.3s ease;
  color: #535b86;
}

.header-sideicons .fa-headset:hover,
.header-sideicons .fa-bell:hover,
.header-sideicons .fa-circle-user:hover {
  color: #535b86;
  background: #e9e9f2;
  transform: rotate(9.02deg);
  transition: transform 0.3s ease;
}

.main-sun {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px;
  background: #fefefe;
  border-radius: 39px;
  transition: background 0.3s ease;
}

.main-sun:hover {
  background: #e9e9f2;
}

.main-sun i {
  color: #626262;
}

#contentProfileDialog {
  left: unset !important;
  right: 32px !important;
}

#contentProfileDialog:after {
  content: '';
  position: absolute;
  right: 5%;
  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}

#contentProfileDialog .profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

#contentProfileDialog .profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid #fc4c02;
  color: #fc4c02;
  font-size: 26px;
}

#contentProfileDialog .profile-details {
  flex-grow: 1;
  padding: 12px 2px;
}

#contentProfileDialog .profile-name {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 36px;
}

#contentProfileDialog .profile-role {
  font-size: 14px;
  color: #666;
  letter-spacing: 1px;
  margin-left: 2px;
}

#contentProfileDialog .settings-icon {
  cursor: pointer;
  font-size: 18px;
  color: #535b86;
}

#contentProfileDialog .footer-actions {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #363636;
  cursor: pointer;
  border-top: 1px solid lightgray;
  /* padding: 21px 8px 10px 8px !important; */
  padding-top: 12px;
}

#contentProfileDialog .footer-actions .change-password {
  padding: 12px;
}

#contentProfileDialog .footer-actions .change-password:hover {
  text-decoration: underline;
}

#contentProfileDialog .logout {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
}

#contentProfileDialog .logout:hover {
  /* text-decoration: underline; */
  background-color: #e9e9f2;
  color: #fc4c01;
  border-radius: 5px;
}

/* Dialog container styling */
#contentNotifyDialog {
  left: unset !important;
  right: 26px !important;
  height: 82vh;
}

/* Triangle indicator */
#contentNotifyDialog:after {
  content: '';
  position: absolute;
  right: 15%;
  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}

/* Notify content area */
#contentNotifyDialog .notifyContent {
  color: #333;
}

#contentNotifyDialog .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  width: 95px !important;
}

#contentNotifyDialog .e-tab .e-content {
  margin-top: 30px !important;
}

#contentNotifyDialog .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  font-size: 14px !important;
}

#contentNotifyDialog .e-tab .e-tab-header .e-toolbar-item.e-active {
  background-color: transparent !important;
  border-top: 1px solid transparent !important;
  border-left: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  border-radius: 5px 5px 0px 0px !important;
}

#contentNotifyDialog .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: #fc4c02 !important;
  padding: 15px 10px;
  border-bottom: 2px solid #fc4c02 !important;
}

#contentNotifyDialog .e-tab .e-tab-header .e-toolbar-item:not(.e-active) {
  border-bottom: none !important;
  border-radius: 0px !important;
}

#contentNotifyDialog .tabicon {
  margin-right: 10px;
  display: inline-block;
}

/* #showHelpDeskDialog {
  width: 200px;
  left: 1032px !important;
  top: 65px !important;
} */

#showHelpDeskDialog {
  left: unset !important;
  right: 62px !important;
  min-width: 0 !important;
  width: 189px !important;
  border-radius: 0px !important;
}

/* Triangle indicator */
#showHelpDeskDialog:after {
  content: '';
  position: absolute;
  left: 25%;
  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}

#showHelpDeskDialog .helpDeskContent #ArutiHelp,
#showHelpDeskDialog .helpDeskContent #ArutiHRHelp:hover {
  border-bottom: 1px solid #e9e9f2;
  padding: 20px 30px;
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: #fffcfb;
  color: #fc4c01;
}

#showHelpDeskDialog .helpDeskContent #ArutiHRHelp {
  border-bottom: 1px solid #e9e9f2;
  padding: 20px 30px;
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
}

#showHelpDeskDialog .e-dlg-content {
  padding: 0px !important;
}

#showCompanylistDialog:after {
  content: '';
  position: absolute;
  left: 33px;
  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}

#showCompanylistDialog .companyListDropdown .ddlContent {
  font-size: 14px;
  font-weight: 400;
  color: #7c7c7c;
  margin-top: 10px;
}

#showCompanylistDialog .companyListDropdown .companylistItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  .companySwitch_list {
  padding: 10px;
  cursor: pointer;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  .companySwitch_list.isTenant {
  border-radius: 8px;
  border-left: 4px solid #fc4c01;
  background-color: #fff4ef;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  .companySwitch_list.notTenant {
  border-radius: 0px;
  border-left: 4px solid #ffffff;
  background-color: #ffffff;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  #companylist
  .comapnylist_leftSide {
  display: flex;
  align-items: center;
  gap: 12px;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  #companylist
  .comapnylist_leftSide
  .companyUserlogo {
  border: 1px solid #fc4c02;
  color: #fc4c02;
  height: 42px;
  width: 42px;
  font-size: 14px;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  #companylist
  .comapnylist_leftSide
  .companyuserdetail {
  font-weight: 500;
  font-size: 16px;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  #companylist
  .comapnylist_rightSide {
  width: 30px;
  height: 30px;
  background-color: #fc4c01;
  color: #ffffff;
  border: 4px solid #ffdccd;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#showCompanylistDialog
  .companyListDropdown
  .companylistItem
  .companySwitch_list.notTenant:hover {
  background-color: #fff4ef !important;
  border-left: 4px solid #fff4ef !important;
  border-radius: 8px !important;
}

#innerSplitterOuterDataViewer {
  height: 100vh !important;
}

.e-splitter.e-splitter-horizontal {
  border: none !important;
  /* spitter selector  border */
}

/* layout css */
#innerSplitterOuterDataViewer {
  height: 100vh !important;
}

.e-splitter.e-splitter-horizontal {
  border: none !important;
  /* spitter selector  border */
}
