#divcustomreport {
  /* height: calc(100vh - 195px); */
  height: calc(100vh - 330px);
  overflow: auto;
  padding: 12px;
  scrollbar-width: none;
}

#divcustomreport .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  /* border-width: 1px 1px 1px 1px; */
  /* font-size: 22px; */
}

#divcustomreport .e-input-group.e-control-wrapper.e-input-focus {
  /* border: 1px solid black !important; */
  padding-left: 5px;
  padding-right: 5px;
}

#divcustomreport .txtName {
  width: calc(100% - 30px);
  margin-top: 12px !important;
}
#divcustomreport .e-inplaceeditor .e-editable-value-wrapper:hover {
  background: #fff !important;
}
#divcustomreport .txtName .e-editable-value-wrapper {
 /* width: 100%; */
  width: 92%;
}
#divcustomreport .e-input-group,
#divcustomreport.e-input-group.e-control-wrapper {
  width: 92% !important;
}
#divcustomreport .txtName .e-editable-value {
  /* font-size: 22px; */
  margin-left: -10px;
  width: 100%;
}

#divcustomreport .ddlRepoGroup,
#divcustomreport [aria-labelledby='reportGroupId_hidden'] {
  width: calc(100% - 10px) !important;
}

#divcustomreport [aria-labelledby='reportGroupId_hidden'] {
  margin-left: 20px;
  border-color: transparent;
}

/* #divcustomreport .reportcategory ~ .e-label {
  font-size: 16px;
} */

/* #divcustomreport .reporttype ~ label .e-label {
  font-size: 16px;
} */

#divcustomreport .customreportaccrlist {
  padding-left: 5px;
  line-height: 40px;
  border: 1px solid lightgray;
}

#divcustomreport .customreportaccrlist .etext {
  padding-left: 30px;
}

#divcustomreport .customreportaccrlist .accrfilter {
  display: flex;
  right: 5px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .customreportaccrlist .accrpivot {
  display: flex;
  right: 34px;
  top: 8px;
  height: 22px;
  width: 22px;
}

#divcustomreport .customreportaccrlist .accrpivotval {
  display: flex;
  right: 5px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .lightgray {
  color: lightgray;
}

#divcustomreport .black {
  color: black;
}

#divcustomreport .lbox-columns .e-list-item {
  padding-left: 5px;
  border: 1px solid lightgray;
  line-height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}
/* #divcustomreport .customreportcolumnlist {
   padding-left: 5px;
  line-height: 40px; 
  border: 1px solid lightgray; 
} */

#divcustomreport .customreportcolumnlist .accclose {
  right: 8px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .customreportcolumnlist .accrsort {
  display: flex;
  right: 38px;
  top: 12px;
  height: 22px;
  width: 22px;
  text-align: center;
}

#divcustomreport .customreportcolumnlist .accrsum {
  display: flex;
  right: 62px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .customreportcolumnlist .accrgroup {
  display: flex;
  right: 88px;
  top: 12px;
  height: 22px;
  width: 22px;
}

/* #divcustomreport .customreportfiltercolumnlist {
  padding-left: 5px;
  line-height: 40px;
  border: 1px solid lightgray;
} */

#divcustomreport .customreportfiltercolumnlist .e-close {
  right: 8px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .customreportpivotrowlist .accclose {
  right: 8px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .customreportpivotvallist .accclose {
  right: 8px;
  top: 12px;
  height: 22px;
  width: 22px;
}

#divcustomreport .fieldcolumn {
  justify-content: start;
  min-height: 400px;
}

#divcustomreport .fieldcolumn .e-card-content {
  height: 335px;
  overflow: auto;
}

#divcustomreport .cardcolumn .e-listbox-wrapper.e-list-template.e-wrapper {
  border: none;
}

#divcustomreport .cardcolumn .e-card-content {
  height: 335px;
  overflow: auto;
}

#divcustomreport .repo-settings .e-radio-wrapper {
  line-height: 26px;
}

#divcustomreport .repo-settings .e-radio-wrapper .e-label {
  /* font-size: 18px; */
  line-height: 11px;
}

#divcustomreport .cardfilter .e-listbox-wrapper.e-list-template.e-wrapper {
  border: none;
}

#divcustomreport .cardpivot .e-listbox-wrapper.e-list-template.e-wrapper {
  border: none;
}

#divcustomreport .cardpivotval .e-listbox-wrapper.e-list-template.e-wrapper {
  border: none;
}

#divcustomreport .footer {
  display: flex;
  position: fixed;
  bottom: 30px;
  left: calc(50% - 235px);
}

#divcustomreport .footer > div {
  display: flex;
  /* font-size: 18px; 
  line-height: 26px;*/
  border: 1px solid darkgray;
  background-color: lightgrey;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  width: 150px;
  margin-left: 10px;
}
.payroll-edit-class-child {
  width: 22% !important;
}
@media (max-width: 768px) {
  #divcustomreport {
    height: calc(100vh - 380px);
  }
  .payroll-edit-class {
    width: 90%;
    margin: 20px auto;
  }
  .col-sm-4 {
    margin-top: 20px;
  }
}