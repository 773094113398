.PeriodSelector {
  margin-left: 15px;
  float: left !important;
}

.e-grid .e-groupcaption {
  font-size: 14px !important;
  background-color: linen;
  font-weight: 500;
}

.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse {
  background-color: linen
}

.e-grid .e-indentcell {
  background-color: transparent;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}