.searchBox-custom-report {
  max-width: 450px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  margin-right: 10px;
}
.searchInput-custom-report {
  width: 100% !important;
}
.searchIcon-custom-report {
  position: absolute !important;
  right: 8px !important;
  top: 1px !important;
}
.custom-report-searchdiv {
  width: 100%;
  border: 1px solid #a3a0a06f !important;
  padding: 5px;
  border-radius: 5px;
}

@media (max-width: 540px) {
  #pivotcustrepopreview #pivotcustrepopreview_grid .e-gridcontent {
    height: calc(100vh - 540px) !important;
    scrollbar-width: none;
  }
  #custrepopreview .custrepopreviewgrid {
    height: calc(100vh - 414px) !important;
  }
  /* table style */
  .e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd),
  .e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd),
  .e-grid .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd),
  .e-grid
    .e-gridcontent
    .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
  .e-grid
    .e-gridheader
    .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
  .e-grid .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd) {
    border-left-width: 0;
    padding: 5px;
    overflow: auto;
    scrollbar-width: none;
  }

  .e-toolbar.e-control[class*='e-toolbar'] {
    overflow: auto;
    scrollbar-width: none;
  }
  #pivotcustrepopreview .e-pivotfieldlist .e-icons {
    background-color: #efefef;
    /* margin-left: auto; */
    margin-right: -10px;
  }
  .periodSelector-input {
    border: none !important;
    font-size: 14px;
    width: 70%;
    font-weight: 300;
    overflow: auto !important;
    min-width: 70%;
    border: none;
    padding: 10px;
    background-color: transparent;
  }
}
