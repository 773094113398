.font-icon {
  cursor: pointer !important;
  color: gray !important;
}
.font-icon:hover {
  color: black !important;
}
#image-dialog .e-dlg-content {
  padding: 0px 12px !important;
}

#image-div {
  overflow: hidden !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid rgb(224, 224, 224) !important;
}

#imgdiv {
  position: absolute !important;
}
#crop-box {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: move;
  background-color: rgb(215 217 217 / 0.25);
  pointer-events: 'auto';
}
#cropbox-img {
  width: 100% !important;
  height: 100% !important;
  cursor: move;
}
#footer-div {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
}
#fileupload-icon {
  cursor: pointer;
  display: none;
}
#footer-btn {
  display: flex;
  align-items: center;
  justify-content: right;
}
