#employeeformat .e-input-group.e-control-wrapper:has(.textbox-noborder) {
  border: 0 !important;
}
.empFormat-label {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: 32px;
}

.empFormat-input {
  width: 45%;
  font-size: 14px;
  line-height: 32px;
  padding: 8px;
  transition: border-color 0.3s;
}

.empFormat-input:focus {
  border-color: #fc4c02;
  outline: none;
}
.empFormat-header {
    width: 100%;
    letter-spacing: 2px;
    background: #f4875833;
    padding: 16px 20px;
    color: #000;
    font-size: 20px;
    font-weight: normal;
    border-radius: 8px 8px 0 0;
    margin: 12px 0px;
}
