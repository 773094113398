.dashboard-main {
  height: calc(100vh - 116px);
  overflow: auto;
}

#default_dashboard.e-dashboardlayout {
  padding: 10px;
  /* width: 100% !important; */
}

#default_dashboard.e-dashboardlayout.e-control .e-panel:hover span {
  display: block;
}

#default_dashboard.e-dashboardlayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

#default_dashboard .e-close-icon {
  padding: 10px;
  float: right;
  display: none;
}

body.tailwind-dark .e-dashboardlayout.e-control .e-panel {
  border: 1px solid #d7d7d7;
}

#default_dashboard .e-clear-icon::before {
  content: '\e100';
  font-size: 12px;
  font-family: 'ej-icon';
}

@font-face {
  font-family: 'ej-icon';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAAKAIAAAwAgT1MvMjdtQ/IAAAEoAAAAVmNtYXDhEOFVAAABiAAAADZnbHlmq8jV4gAAAcgAAAFQaGVhZBSREIsAAADQAAAANmhoZWEIUAQDAAAArAAAACRobXR4CAAAAAAAAYAAAAAIbG9jYQCoAAAAAAHAAAAABm1heHABDQCUAAABCAAAACBuYW1lWD3y/QAAAxgAAAIlcG9zdMl9cHoAAAVAAAAALwABAAAEAAAAAFwEAAAAAAAD8wABAAAAAAAAAAAAAAAAAAAAAgABAAAAAQAAjGeuW18PPPUACwQAAAAAANi/5c0AAAAA2L/lzQAAAAAD8wP0AAAACAACAAAAAAAAAAEAAAACAIgAAQAAAAAAAgAAAAoACgAAAP8AAAAAAAAAAQQAAZAABQAAAokCzAAAAI8CiQLMAAAB6wAyAQgAAAIABQMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAUGZFZABA4QDhAAQAAAAAXAQAAAAAAAABAAAAAAAABAAAAAQAAAAAAAACAAAAAwAAABQAAwABAAAAFAAEACIAAAAEAAQAAQAA4QD//wAA4QD//wAAAAEABAAAAAEAAAAAAAAAqAAAAAEAAAAAA/MD9ACHAAAJAi8GKwEPDR0BHwYJAQ8GHQEfDTsBPwYJAR8GOwE/Di8HCQE/Bj0BLw0rAQ8FA3T+jP6MBQYHBgcHCAcHBwcHBwYGBgUFAwMDAgEBAgMDAwUFAXT+jAUFAwMDAgEBAgMDAwUFBgYGBwcHBwcHBwgGBwYHBQF0AXQFBgcGBwcIBwcHBwcHBgYGBQQEAwMBAQEBAQEDAwQEBf6MAXQFBQMDAwIBAQIDAwMFBQYGBgcHBwcHBwgHBwYHBgPe/owBdAUFAwMDAgEBAgMDAwUFBgYGBwcHBwcHCAcGBwYHBf6M/owFBwYHBgcIBwcHBwcHBgYGBQUDBAICAQECAgQDBQUBdP6MBQUDBAICAQECAgQDBQUGBgYHBwcHBwcIBwYHBgcFAXQBdAYGBgcHBwcHBwcHBwcGBgYFBQMEAgIBAQIDAwMFAAAAEgDeAAEAAAAAAAAAAQAAAAEAAAAAAAEABwABAAEAAAAAAAIABwAIAAEAAAAAAAMABwAPAAEAAAAAAAQABwAWAAEAAAAAAAUACwAdAAEAAAAAAAYABwAoAAEAAAAAAAoALAAvAAEAAAAAAAsAEgBbAAMAAQQJAAAAAgBtAAMAAQQJAAEADgBvAAMAAQQJAAIADgB9AAMAAQQJAAMADgCLAAMAAQQJAAQADgCZAAMAAQQJAAUAFgCnAAMAAQQJAAYADgC9AAMAAQQJAAoAWADLAAMAAQQJAAsAJAEjIGVqLWljb25SZWd1bGFyZWotaWNvbmVqLWljb25WZXJzaW9uIDEuMGVqLWljb25Gb250IGdlbmVyYXRlZCB1c2luZyBTeW5jZnVzaW9uIE1ldHJvIFN0dWRpb3d3dy5zeW5jZnVzaW9uLmNvbQAgAGUAagAtAGkAYwBvAG4AUgBlAGcAdQBsAGEAcgBlAGoALQBpAGMAbwBuAGUAagAtAGkAYwBvAG4AVgBlAHIAcwBpAG8AbgAgADEALgAwAGUAagAtAGkAYwBvAG4ARgBvAG4AdAAgAGcAZQBuAGUAcgBhAHQAZQBkACAAdQBzAGkAbgBnACAAUwB5AG4AYwBmAHUAcwBpAG8AbgAgAE0AZQB0AHIAbwAgAFMAdAB1AGQAaQBvAHcAdwB3AC4AcwB5AG4AYwBmAHUAcwBpAG8AbgAuAGMAbwBtAAAAAAIAAAAAAAAACgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgECAQMABWNsb3NlAAAA) format('truetype');
  font-weight: normal;
  font-style: normal;
}

#default_dashboard .text-align {
  line-height: 160px;
}

#default_dashboard .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

#default_target .addContainer {
  padding: 5px;
  text-align: right;
  height: 35px;
  width: 100%;
  margin-bottom: 5px;
}

#default_target #add {
  float: right;
}

#default_dashboard #layout_0,
#default_dashboard #layout_2 {
  height: auto !important;
  /* min-height: 500px; */
}

/* #default_dashboard #layout_1 {
  height: auto !important;
  min-height: calc(100vh - 115px);
} */

.default_dashboard_defaultLayout1>.maindiv {
  height: calc(100vh - 110px);
  width: calc(100% + 13px);
  overflow: auto;
}

#default_dashboard .column-1 {
  max-width: 410px;

  @media screen and (max-width: 768px) {
    max-width: unset;
  }
}

#default_dashboard .column-2 {
  width: calc(100% - 935px);
}

#default_dashboard .column-3 {
  max-width: 520px;

  @media screen and (max-width: 768px) {
    max-width: unset;
  }
}

@media screen and (max-width: 768px) {
  #default_dashboard .column-1 {
    width: 100%;
  }

  #default_dashboard .column-2 {
    width: 100%;
  }

  #default_dashboard .column-3 {
    width: 100%;
  }
}

#divbtn .e-btn {
  text-transform: none !important;
}
