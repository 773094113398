/* .customGrid .e-unboundcell .e-unboundcelldiv button[title='Edit'] {
  display: none;
}

.customGrid
  .e-row:hover
  .e-unboundcell
  .e-unboundcelldiv
  button[title='Edit']:not(.e-hide) {
  display: block;
} */

.holiday-grid {
  padding: 20px;
  margin: 0px 1%;
  background-color: #fff;
  border-radius: 10px;
  scrollbar-width: thin;
}
#maintitle {
  letter-spacing: 1.5px;
  padding-top: 10px !important;
  margin: 20px 0px !important;
}
.customGrid {
  border: none;
  color: #333;
  border-radius: 8px;
  margin-bottom: 15px;
}
.holiday-grid .header {
  margin-bottom: 20px;
  width: 100%;
  letter-spacing: 1.5px;
  background: linear-gradient(
    to right,
    #615e5d0a 0%,
    #a5a3a03c 51%,
    #48464516 100%
  );
  padding: 16px 20px;
  border-radius: 8px 8px 0 0;
}
.email-header {
  letter-spacing: 1.5px !important;
  font-size: 22px !important;
}
.holiday-grid .header-label {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #000;
}

.holiday-grid .grid-wrapper {
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  /* margin: 20px 1px; */
}

/* grid commands */
#divholidaylist
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn
  .e-tbar-btn-text {
  color: rgba(0, 0, 0, 0.87);
}

#divholidaylist .e-toolbar .e-toolbar-item .e-tbar-btn {
  background: #fafafa;
  border: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 6px 12px;
  margin: 0 5px;
  transition: background 0.3s;
}

#divholidaylist .e-toolbar .e-toolbar-item .e-tbar-btn:hover {
  background: #e0e0e0d3;
}

#divholidaylist .e-toolbar .e-toolbar-item .e-tbar-btn:focus {
  outline: none;
}

#divholidaylist .e-toolbar .e-toolbar-item .e-tbar-btn .e-icons {
  margin-right: 4px;
}
#divholidaylist .holiday-grid .e-toolbar.e-control[class*='e-toolbar'] {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}
#divholidaylist .e-toolbar .e-toolbar-item {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 6px 12px;
  margin: 0 5px;
  transition: background 0.3s;
}
#divholidaylist .e-toolbar .e-toolbar-item:hover {
  background: #e0e0e0d3;
}
#divholidaylist .e-toolbar {
  background-color: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border-radius: 4px;
  padding: 10px;
}
/* holiday chk */
.import-check-class {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  width: 100px;
  border-radius: 5px;
  margin-left: auto;
}
